var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;
var exports = {};
!function (e, t) {
  exports = t();
}(self, () => (() => {
  var e = {
      571: function (e, t) {
        var n, i;
        n = function (e) {
          "use strict";

          var t = function () {
              function e(e, t) {
                for (var n = 0; n < t.length; n++) {
                  var i = t[n];
                  i.enumerable = i.enumerable || !1, i.configurable = !0, "value" in i && (i.writable = !0), Object.defineProperty(e, i.key, i);
                }
              }
              return function (t, n, i) {
                return n && e(t.prototype, n), i && e(t, i), t;
              };
            }(),
            n = function () {
              function e(t) {
                (function (e, t) {
                  if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function");
                })(this || _global, e), (this || _global).target = t, (this || _global).isContentEditable = t && t.contentEditable;
              }
              return t(e, [{
                key: "getPos",
                value: function () {
                  if (document.activeElement !== (this || _global).target) return -1;
                  if ((this || _global).isContentEditable) {
                    (this || _global).target.focus();
                    var e = document.getSelection().getRangeAt(0),
                      t = e.cloneRange();
                    return t.selectNodeContents((this || _global).target), t.setEnd(e.endContainer, e.endOffset), t.toString().length;
                  }
                  return (this || _global).target.selectionStart;
                }
              }, {
                key: "setPos",
                value: function (e) {
                  if ((this || _global).isContentEditable) {
                    if (e >= 0) {
                      var t = window.getSelection(),
                        n = this.createRange((this || _global).target, {
                          count: e
                        });
                      n && (n.collapse(!1), t.removeAllRanges(), t.addRange(n));
                    }
                  } else (this || _global).target.setSelectionRange(e, e);
                }
              }, {
                key: "createRange",
                value: function (e, t, n) {
                  if (n || ((n = document.createRange()).selectNode(e), n.setStart(e, 0)), 0 === t.count) n.setEnd(e, t.count);else if (e && t.count > 0) if (e.nodeType === Node.TEXT_NODE) e.textContent.length < t.count ? t.count -= e.textContent.length : (n.setEnd(e, t.count), t.count = 0);else for (var i = 0; i < e.childNodes.length && (n = this.createRange(e.childNodes[i], t, n), 0 !== t.count); i++);
                  return n;
                }
              }]), e;
            }();
          e.exports = n;
        }, void 0 === (i = n.apply(t, [e])) || (e.exports = i);
      },
      334: (e, t, n) => {
        e.exports = n(571);
      }
    },
    t = {};
  function n(i) {
    var o = t[i];
    if (void 0 !== o) return o.exports;
    var r = t[i] = {
      exports: {}
    };
    return e[i].call(r.exports, r, r.exports, n), r.exports;
  }
  n.n = e => {
    var t = e && e.__esModule ? () => e.default : () => e;
    return n.d(t, {
      a: t
    }), t;
  }, n.d = (e, t) => {
    for (var i in t) n.o(t, i) && !n.o(e, i) && Object.defineProperty(e, i, {
      enumerable: !0,
      get: t[i]
    });
  }, n.o = (e, t) => Object.prototype.hasOwnProperty.call(e, t);
  var i = {};
  return (() => {
    "use strict";

    n.d(i, {
      default: () => u
    });
    var e = n(334),
      t = n.n(e);
    function o(e, t) {
      for (var n = 0; n < t.length; n++) {
        var i = t[n];
        i.enumerable = i.enumerable || !1, i.configurable = !0, "value" in i && (i.writable = !0), Object.defineProperty(e, i.key, i);
      }
    }
    var r = function () {
      function e(t, n, i) {
        !function (e, t) {
          if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function");
        }(this || _global, e), (this || _global).holder = n, (this || _global).observer = null, (this || _global).debounceTimer = i, (this || _global).mutationDebouncer = this.debounce(function () {
          t();
        }, (this || _global).debounceTimer);
      }
      var t, n;
      return t = e, n = [{
        key: "setMutationObserver",
        value: function () {
          var e = this || _global,
            t = (this || _global).holder.querySelector(".codex-editor__redactor");
          (this || _global).observer = new MutationObserver(function (t) {
            e.mutationHandler(t);
          }), (this || _global).observer.observe(t, {
            childList: !0,
            attributes: !0,
            subtree: !0,
            characterData: !0,
            characterDataOldValue: !0
          });
        }
      }, {
        key: "mutationHandler",
        value: function (e) {
          var t = this || _global,
            n = !1;
          e.forEach(function (e) {
            switch (e.type) {
              case "childList":
                e.target === t.holder ? t.onDestroy() : n = !0;
                break;
              case "characterData":
                n = !0;
                break;
              case "attributes":
                e.target.classList.contains("ce-block") || e.target.classList.contains("tc-toolbox") || (n = !0);
            }
          }), n && this.mutationDebouncer();
        }
      }, {
        key: "debounce",
        value: function (e, t) {
          var n,
            i = this || _global;
          return function () {
            for (var o = arguments.length, r = new Array(o), s = 0; s < o; s++) r[s] = arguments[s];
            var a = i;
            clearTimeout(n), n = setTimeout(function () {
              return e.apply(a, r);
            }, t);
          };
        }
      }, {
        key: "onDestroy",
        value: function () {
          var e = new CustomEvent("destroy");
          document.dispatchEvent(e), (this || _global).observer.disconnect();
        }
      }], n && o(t.prototype, n), Object.defineProperty(t, "prototype", {
        writable: !1
      }), e;
    }();
    function s(e, t) {
      var n = Object.keys(e);
      if (Object.getOwnPropertySymbols) {
        var i = Object.getOwnPropertySymbols(e);
        t && (i = i.filter(function (t) {
          return Object.getOwnPropertyDescriptor(e, t).enumerable;
        })), n.push.apply(n, i);
      }
      return n;
    }
    function a(e) {
      for (var t = 1; t < arguments.length; t++) {
        var n = null != arguments[t] ? arguments[t] : {};
        t % 2 ? s(Object(n), !0).forEach(function (t) {
          c(e, t, n[t]);
        }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(n)) : s(Object(n)).forEach(function (t) {
          Object.defineProperty(e, t, Object.getOwnPropertyDescriptor(n, t));
        });
      }
      return e;
    }
    function c(e, t, n) {
      return t in e ? Object.defineProperty(e, t, {
        value: n,
        enumerable: !0,
        configurable: !0,
        writable: !0
      }) : e[t] = n, e;
    }
    function l(e, t) {
      for (var n = 0; n < t.length; n++) {
        var i = t[n];
        i.enumerable = i.enumerable || !1, i.configurable = !0, "value" in i && (i.writable = !0), Object.defineProperty(e, i.key, i);
      }
    }
    var u = function () {
      function e(t) {
        var n = this || _global,
          i = t.editor,
          o = t.config,
          s = void 0 === o ? {} : o,
          c = t.onUpdate,
          l = t.maxLength;
        !function (e, t) {
          if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function");
        }(this || _global, e);
        var u = {
            maxLength: 30,
            onUpdate: function () {},
            config: {
              debounceTimer: 200,
              shortcuts: {
                undo: ["CMD+Z"],
                redo: ["CMD+Y", "CMD+SHIFT+Z"]
              }
            }
          },
          h = i.blocks,
          d = i.caret,
          f = i.configuration,
          p = f.holder,
          v = u.config.shortcuts,
          k = s.shortcuts,
          y = a(a({}, v), k),
          b = Array.isArray(y.undo) ? y.undo : [y.undo],
          g = Array.isArray(y.redo) ? y.redo : [y.redo],
          m = u.config.debounceTimer,
          x = s.debounceTimer,
          O = void 0 === x ? m : x;
        (this || _global).holder = "string" == typeof p ? document.getElementById(p) : p, (this || _global).editor = i, (this || _global).blocks = h, (this || _global).caret = d, (this || _global).shouldSaveHistory = !0, (this || _global).readOnly = f.readOnly, (this || _global).maxLength = l || u.maxLength, (this || _global).onUpdate = c || u.onUpdate, (this || _global).config = {
          debounceTimer: O,
          shortcuts: {
            undo: b,
            redo: g
          }
        }, new r(function () {
          return n.registerChange();
        }, (this || _global).holder, (this || _global).config.debounceTimer).setMutationObserver(), this.setEventListeners(), (this || _global).initialItem = null, this.clear();
      }
      var n, i, o;
      return n = e, o = [{
        key: "isReadOnlySupported",
        get: function () {
          return !0;
        }
      }], (i = [{
        key: "truncate",
        value: function (e, t) {
          for (; e.length > t;) e.shift();
        }
      }, {
        key: "initialize",
        value: function (e) {
          var t = "blocks" in e ? e.blocks : e,
            n = {
              index: t.length - 1,
              state: t
            };
          (this || _global).stack[0] = n, (this || _global).initialItem = n;
        }
      }, {
        key: "clear",
        value: function () {
          (this || _global).stack = (this || _global).initialItem ? [(this || _global).initialItem] : [{
            index: 0,
            state: [{
              type: "paragraph",
              data: {
                text: ""
              }
            }]
          }], (this || _global).position = 0, this.onUpdate();
        }
      }, {
        key: "setReadOnly",
        value: function () {
          var e = (this || _global).holder.querySelector(".ce-toolbox");
          (this || _global).readOnly = !e;
        }
      }, {
        key: "registerChange",
        value: function () {
          var e = this || _global;
          this.setReadOnly(), (this || _global).readOnly || ((this || _global).editor && (this || _global).editor.save && (this || _global).shouldSaveHistory && (this || _global).editor.save().then(function (t) {
            e.editorDidUpdate(t.blocks) && e.save(t.blocks);
          }), (this || _global).shouldSaveHistory = !0);
        }
      }, {
        key: "editorDidUpdate",
        value: function (e) {
          var t = (this || _global).stack[(this || _global).position].state;
          return !!e.length && (e.length !== t.length || JSON.stringify(t) !== JSON.stringify(e));
        }
      }, {
        key: "save",
        value: function (e) {
          (this || _global).position >= (this || _global).maxLength && this.truncate((this || _global).stack, (this || _global).maxLength), (this || _global).position = Math.min((this || _global).position, (this || _global).stack.length - 1), (this || _global).stack = (this || _global).stack.slice(0, (this || _global).position + 1);
          var t = (this || _global).blocks.getCurrentBlockIndex(),
            n = (this || _global).blocks.getBlocksCount(),
            i = t;
          e[t] || (i -= n - e.length);
          var o = !e[i] || "paragraph" !== e[i].type && "header" !== e[i].type ? null : this.getCaretIndex(t);
          (this || _global).stack.push({
            index: i,
            state: e,
            caretIndex: o
          }), (this || _global).position += 1, this.onUpdate();
        }
      }, {
        key: "getCaretIndex",
        value: function (e) {
          var n = (this || _global).holder.getElementsByClassName("ce-block__content");
          return new (t())(n[e].firstChild).getPos();
        }
      }, {
        key: "insertDeletedBlock",
        value: function (e, t, n) {
          for (var i = 0; i < e.length; i += 1) if (!t[i] || e[i].id !== t[i].id) {
            (this || _global).blocks.insert(e[i].type, e[i].data, {}, i, !0), (this || _global).caret.setToBlock(n, "end");
            break;
          }
        }
      }, {
        key: "blockWasDropped",
        value: function (e, t) {
          return e.length === t.length && e.some(function (e, n) {
            return e.id !== t[n].id;
          });
        }
      }, {
        key: "blockWasSkipped",
        value: function (e, t, n, i) {
          return e < t && n.length !== i.length;
        }
      }, {
        key: "contentChangedInNoFocusBlock",
        value: function (e, t) {
          return e !== t;
        }
      }, {
        key: "blockWasDeleted",
        value: function (e, t) {
          return e.length > t.length;
        }
      }, {
        key: "undo",
        value: function () {
          var e = this || _global;
          if (this.canUndo() && (this || _global).position >= 0) {
            (this || _global).position -= 1, (this || _global).shouldSaveHistory = !1;
            var t = (this || _global).stack[(this || _global).position].index,
              n = (this || _global).stack[(this || _global).position],
              i = n.state,
              o = n.caretIndex,
              r = (this || _global).stack[(this || _global).position],
              s = r.index,
              a = r.state;
            this.onUpdate();
            var c = (this || _global).blocks.getBlocksCount();
            if (i[t] || (t -= 1, (this || _global).stack[(this || _global).position].index = t), this.blockWasDeleted(i, a)) this.insertDeletedBlock(i, a, t);else if (this.blockWasSkipped(t, s, i, a)) (this || _global).blocks.delete(), (this || _global).caret.setToBlock(t, "end");else if (c > i.length) (this || _global).blocks.render({
              blocks: i
            }).then(function () {
              e.editor.blocks.insert("paragraph", {
                text: ""
              }), e.caret.setToLastBlock("end");
            });else if (this.blockWasDropped(i, a)) (this || _global).blocks.render({
              blocks: i
            }).then(function () {
              return e.caret.setToBlock(t, "end");
            });else if (this.contentChangedInNoFocusBlock(t, s)) {
              var l = (this || _global).blocks.getBlockByIndex(s).id;
              (this || _global).blocks.update(l, i[s].data), this.setCaretIndex(t, o);
            }
            var u = (this || _global).blocks.getBlockByIndex(t);
            u && ((this || _global).blocks.update(u.id, i[t].data), this.setCaretIndex(t, o));
          }
        }
      }, {
        key: "setCaretIndex",
        value: function (e, n) {
          if (n && -1 !== n) {
            var i = (this || _global).holder.getElementsByClassName("ce-block__content");
            new (t())(i[e].firstChild).setPos(n);
          } else (this || _global).caret.setToBlock(e, "end");
        }
      }, {
        key: "insertBlock",
        value: function (e, t) {
          (this || _global).blocks.insert(e[t].type, e[t].data, {}, t, !0);
        }
      }, {
        key: "insertSkippedBlocks",
        value: function (e, t) {
          for (var n = e; n < t.length; n += 1) this.insertBlock(t, n);
        }
      }, {
        key: "redo",
        value: function () {
          var e = this || _global;
          if (this.canRedo()) {
            (this || _global).position += 1, (this || _global).shouldSaveHistory = !1;
            var t = (this || _global).stack[(this || _global).position],
              n = t.index,
              i = t.state,
              o = t.caretIndex,
              r = (this || _global).stack[(this || _global).position - 1],
              s = r.index,
              a = r.state;
            this.blockWasDeleted(a, i) ? ((this || _global).blocks.delete(), (this || _global).caret.setToBlock(n, "end")) : this.blockWasSkipped(s, n, i, a) ? (this.insertSkippedBlocks(a.length, i), (this || _global).caret.setToBlock(n, "end")) : this.blockWasDropped(i, a) && 1 !== (this || _global).position && (this || _global).blocks.render({
              blocks: i
            }).then(function () {
              return e.caret.setToBlock(n, "end");
            }), this.onUpdate();
            var c = (this || _global).blocks.getBlockByIndex(n);
            c && ((this || _global).blocks.update(c.id, i[n].data), this.setCaretIndex(n, o));
          }
        }
      }, {
        key: "canUndo",
        value: function () {
          return !(this || _global).readOnly && (this || _global).position > 0;
        }
      }, {
        key: "canRedo",
        value: function () {
          return !(this || _global).readOnly && (this || _global).position < this.count();
        }
      }, {
        key: "count",
        value: function () {
          return (this || _global).stack.length - 1;
        }
      }, {
        key: "parseKeys",
        value: function (e) {
          var t = {
              CMD: /(Mac)/i.test(navigator.platform) ? "metaKey" : "ctrlKey",
              ALT: "altKey",
              SHIFT: "shiftKey"
            },
            n = e.slice(0, -1).map(function (e) {
              return t[e];
            }),
            i = n.includes("shiftKey") && 2 === e.length ? e[e.length - 1].toUpperCase() : e[e.length - 1].toLowerCase();
          return n.push(i), n;
        }
      }, {
        key: "setEventListeners",
        value: function () {
          var e = this || _global,
            t = (this || _global).holder,
            n = (this || _global).config.shortcuts,
            i = n.undo,
            o = n.redo,
            r = i.map(function (e) {
              return e.replace(/ /g, "").split("+");
            }),
            s = o.map(function (e) {
              return e.replace(/ /g, "").split("+");
            }),
            a = r.map(function (t) {
              return e.parseKeys(t);
            }),
            c = s.map(function (t) {
              return e.parseKeys(t);
            }),
            l = function (e) {
              return e.replace("Key", "").toLowerCase();
            },
            u = function (e, t) {
              return t.reduce(function (t, n) {
                return t || function (e, t) {
                  return 3 === t.length && e[t[0]] && e[t[1]] && (e.key === t[2] || l(e.code) === t[2]);
                }(e, n);
              }, !1);
            },
            h = function (e, t, n) {
              return !(!function (e, t) {
                return t.reduce(function (t, n) {
                  return t || function (e, t) {
                    return 2 === t.length && e[t[0]] && (e.key === t[1] || l(e.code) === t[1]);
                  }(e, n);
                }, !1);
              }(e, t) || u(e, n)) || !!u(e, t);
            },
            d = function (t) {
              h(t, a, c) && (t.preventDefault(), e.undo());
            },
            f = function (t) {
              h(t, c, a) && (t.preventDefault(), e.redo());
            };
          t.addEventListener("keydown", d), t.addEventListener("keydown", f), t.addEventListener("destroy", function () {
            t.removeEventListener("keydown", d), t.removeEventListener("keydown", f);
          });
        }
      }]) && l(n.prototype, i), o && l(n, o), Object.defineProperty(n, "prototype", {
        writable: !1
      }), e;
    }();
  })(), i.default;
})());
export default exports;
export const Undo = exports.Undo;